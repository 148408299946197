.banner {
	background: var(--black) url("../../assets/images/location-img.png");
	background-size: contain;
	background-position-x: 0;
	background-repeat: no-repeat;
	border-radius: var(--size-12);
	height: 300px;
}

.location-container {
	width: 100%;
}

.location-content {
	align-items: flex-start;
	justify-content: space-evenly;
}
