.hero-container {
	height: calc(100vh - var(--size-64));
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero {
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1080px;
}

.hero-left {
	flex-basis: 60%;
	align-self: flex-start;
	margin-top: 2em;
}

.hero-img {
	width: 100%;
	object-fit: contain;
}

@media screen and (max-width: 768px) {
	.hero-container {
		padding-top: var(--size-48);
		height: fit-content !important;
		margin-bottom: var(--size-48);
	}

	.hero {
		flex-direction: column;
	}

	.hero-left {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--size-12);
	}
}
