.btn.primary-btn {
	height: 42px;
	width: 176px;

	background: linear-gradient(95deg, #f0c -52.02%, #339 120.56%);
	appearance: none;
	outline: 0px;
	border: 0px;

	border-radius: 30px;

	font-size: var(--size-18);
	color: #fff;
}

.btn.btn-secondary {
	height: 36px;
	width: 168px;

	background: var(--black);
	appearance: none;
	outline: 0px;
	border: 0px;

	border-radius: 30px;

	font-size: var(--size-14);
	color: #fff;
}

.btn.btn-tertiary {
	height: 36px;
	width: 168px;

	background: var(--white);
	appearance: none;
	outline: 0px;
	border: 0px;

	border-radius: 30px;

	font-size: var(--size-14);
	color: var(--black);
}
