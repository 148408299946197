form * {
	display: block;
}

form.sign-in-form {
	width: 400px;
	border-radius: var(--size-32);
	position: relative;
}

/* OTHER STYLES ARE IN FORM.CSS */

.close-btn {
	position: absolute;
	top: var(--size-28);
	right: var(--size-28);

	border: 0;
	outline: 0;
}

.close-btn:hover {
	background: var(--black);
	color: var(--white);
}

.sign-in-up-btns {
	width: 70%;
	height: var(--size-48);

	background: var(--disabled-black);

	border-radius: var(--size-8);
}

.sign-in-btn,
.sign-up-btn {
	flex-grow: 1;
	height: 100%;
	border-radius: var(--size-8);
}

button.g-login {
	position: relative;

	width: 100%;
	height: var(--size-48);

	background: var(--white);
	color: var(--black);
	padding: var(--size-8);

	outline: 0;
	border: 1px solid var(--black);
	border-radius: var(--size-4);

	gap: var(--size-12);

	/* border-bottom: 1px solid var(--white); */
	/* border-bottom-right-radius: 0px; */
	/* border-bottom-left-radius: 0px; */
}

button.g-login:hover {
	box-shadow: 2px 4px 15px #1e1e1e83;
	border: 0px solid var(--black);
	background: var(--primary-gradient);
	color: var(--white);
	gap: var(--size-14);
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
	form.sign-in-form {
		width: 95vw;
	}
}
