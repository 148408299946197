.eclipse-container {
	height: 200px;
	width: 200px;

	flex-direction: column;
	justify-content: center;
	gap: var(--size-12);

	text-align: center;

	padding: var(--size-28);

	border-radius: 50%;
	border: 2px solid var(--black);
}

.eclipse-container:hover {
	box-shadow: 0 0 45px -10px #1e1e1e32;
}
@media screen and (max-width: 576px) {
	.eclipse-container {
		height: 150px;
		width: 150px;
		flex: 1 0 150px;
	}

	.eclipse-icon svg {
		height: 40px;
		width: 40px;
	}
}
