*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
/* SCROLLBAR UI */

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--primary-gradient);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: var(--size-8);
}

*::-webkit-scrollbar-track {
	background: #fff;
}

*::-webkit-scrollbar-thumb {
	background: var(--secondary-gradient);
	border-radius: 4px;
}

/* USER SELECTION COLORS */
*::selection {
	background-color: #f0916b8c;
	color: var(--black);
}
/* VARIABLES */

:root {
	--size-4: 0.25rem;
	--size-8: calc(var(--size-4) * 2);
	--size-12: calc(var(--size-4) * 3);
	--size-14: 0.875rem;
	--size-16: calc(var(--size-4) * 4);
	--size-18: 1.125rem;
	--size-20: calc(var(--size-4) * 5);
	--size-24: calc(var(--size-4) * 6);
	--size-28: calc(var(--size-4) * 7);
	--size-32: calc(var(--size-4) * 8);
	--size-36: calc(var(--size-4) * 9);
	--size-40: calc(var(--size-4) * 10);
	--size-48: calc(var(--size-4) * 12);
	--size-64: calc(var(--size-4) * 16);

	--primary-gradient: linear-gradient(95deg, #f0c -52.02%, #339 120.56%);
	--secondary-gradient: linear-gradient(
		100deg,
		#b513b9 11.94%,
		#552ba1 54.1%,
		#f0916b 106.93%,
		#e787a0 146.76%
	);
	--tertiary-gradient: linear-gradient(
		100deg,
		#b513b9 11.94%,
		#552ba1 54.1%,
		#f0916b 60.93%,
		#e787a0 90.76%
	);

	--white: #ffffff;
	--black: #1e1e1e;
	--disabled-black: #ebebeb;
	--accent-black: #000000;
}

.dark-theme {
	--white: #000;
	--black: #fff;
}

.visually-hidden {
	/* Contain text within 1px box */
	height: 1px;
	overflow: hidden;
	width: 1px;
	/* Keep the layout */
	position: absolute;
	/* Remove any visible trace (e.g. background color) */
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%); /* browsers in the future */
	/* Prevent the screen reader to skip spaces between words */
	white-space: nowrap;
}
/* MAX WIDTH */
.max-width {
	max-width: 1080px;
}
@font-face {
	font-family: "Montserrat";
	src: url("/src/assets/fonts/montserrat-variablefont.ttf");
}

@font-face {
	font-family: "Montserrat Italic";
	src: url("/src/assets/fonts/montserrat-italic-variablefont.ttf");
}

@font-face {
	font-family: "Marcellus";
	src: url("/src/assets/fonts/marcellus-regular.ttf");
}

/* FONT COLORS  */
.font-color-primary {
	background: var(--secondary-gradient);
	background-clip: text;
	color: transparent;
}

/* .font-color-primary-2 {
	background: var(--tertiary-gradient);
	background-clip: text;
	color: transparent;
} */

.font-color-sec {
	color: var(--black);
}

.font-color-ter {
	color: var(--white);
}

/* FONT FAMILY CLASSES */
.font-primary {
	font-family: "Montserrat", sans-serif;
}

.font-primary-italic {
	font-family: "Montserrat Italic", sans-serif;
}

.font-secondary {
	font-family: "Marcellus", sans-serif;
	letter-spacing: 1px;
}

/* FONT WEIGHT CLASSES */
.font-regular {
	font-weight: 400;
}
.font-bolder {
	font-weight: 800;
}

.font-bold {
	font-weight: 700;
}

.font-medium {
	font-weight: 500;
}

/* MARGINS */

/* TOP MARGINS */
.margin-small-top {
	margin-top: var(--size-16);
}

/* BOTTOM MARGINS */
.margin-small-bottom {
	margin-bottom: var(--size-16);
}

/* section bottom margin */
.margin-medium-bottom {
	margin-bottom: var(--size-48);
}

.margin-large-bottom {
	margin-bottom: var(--size-64);
}

/* left and right margin for sections */
.margin-medium-inline {
	margin-left: var(--size-48);
	margin-right: var(--size-48);
}

.margin-large-inline {
	margin-left: var(--size-64);
	margin-right: var(--size-64);
}
/* left and right padding  */
.padding-large-inline {
	padding-left: var(--size-48);
	padding-right: var(--size-48);
}

.padding-medium-inline {
	padding-left: var(--size-48);
	padding-right: var(--size-48);
}

/* left and right padding  */
.padding-small-inline {
	padding-left: var(--size-18);
	padding-right: var(--size-18);
}

/* top padding  */
.padding-medium-top {
	padding-top: var(--size-48);
}

/* bottom padding  */
.padding-medium-bottom {
	padding-bottom: var(--size-48);
}

/* FONT SIZES */
h1 {
	font-size: var(--size-36);
}

h2 {
	font-size: var(--size-28);
}

.body-font {
	font-size: var(--size-16);
}

/* nav bar */
.font-small {
	font-size: var(--size-14);
}

/* CENTER A DIV */
.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Flex direction column */
.flex-column {
	flex-direction: column;
}

/* LINKS UI */
a,
a:visited,
a:active,
a:focus,
a:link {
	text-decoration: none;
	color: var(--black);
}

/* BUTTONS HOVER */

button:hover {
	cursor: pointer;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	/* MARGINS */

	/* TOP MARGINS */
	.margin-small-top {
		margin-top: var(--size-16);
	}

	/* BOTTOM MARGINS */
	.margin-small-bottom {
		margin-bottom: var(--size-16);
	}

	/* section bottom margin */
	.margin-medium-bottom {
		margin-bottom: var(--size-48);
	}

	.margin-large-bottom {
		margin-bottom: var(--size-64);
	}

	/* left and right margin for sections */
	.margin-medium-inline {
		margin-left: var(--size-24);
		margin-right: var(--size-24);
	}

	.margin-large-inline {
		margin-left: var(--size-48);
		margin-right: var(--size-68);
	}

	.padding-large-inline {
		padding-left: var(--size-24);
		padding-right: var(--size-24);
	}

	/* left and right padding  */
	.padding-medium-inline {
		padding-left: var(--size-20);
		padding-right: var(--size-20);
	}

	/* left and right padding  */
	.padding-small-inline {
		padding-left: var(--size-14);
		padding-right: var(--size-14);
	}

	/* top padding  */
	.padding-medium-top {
		padding-top: var(--size-48);
	}

	/* bottom padding  */
	.padding-medium-bottom {
		padding-bottom: var(--size-48);
	}

	/* FONT SIZES */
	h1 {
		font-size: var(--size-28);
	}

	h2 {
		font-size: var(--size-20);
	}
}

@media screen and (max-width: 576px) {
	/* MARGINS */
	/* left and right margin for sections */
	.margin-medium-inline {
		margin-left: var(--size-18);
		margin-right: var(--size-18);
	}

	.margin-large-inline {
		margin-left: var(--size-32);
		margin-right: var(--size-32);
	}
	/* left and right padding  */

	.padding-large-inline {
		padding-left: var(--size-20);
		padding-right: var(--size-20);
	}

	.padding-medium-inline {
		padding-left: var(--size-16);
		padding-right: var(--size-16);
	}

	/* left and right padding  */
	.padding-small-inline {
		padding-left: var(--size-12);
		padding-right: var(--size-12);
	}
	/* FONT SIZES */
	h1 {
		font-size: var(--size-28);
	}

	h2 {
		font-size: var(--size-20);
	}
}
