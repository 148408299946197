form * {
	display: block;
}

form {
	background: var(--white);

	width: 400px;
	border-radius: var(--size-20);
	border: 1px solid var(--black);

	box-shadow: 2px 6px 20px #1e1e1e28;
}

form:not(form.sign-in-form):hover {
	background: var(--primary-gradient);
}

input,
textarea,
select {
	position: relative;

	width: 100%;
	height: var(--size-48);

	background: var(--white);
	color: var(--black);
	padding: var(--size-8);

	outline: 0;
	border: 1px solid var(--black);

	/* border-bottom: 1px solid var(--white); */
	border-radius: var(--size-4);
	/* border-bottom-right-radius: 0px; */
	/* border-bottom-left-radius: 0px; */
}

textarea {
	height: 112px;

	resize: none;
}

.number-container {
	width: 100%;
}

.cunt-code {
	flex-basis: 25%;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 1px solid var(--white);

	background: var(--white);
}

.number {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

@media screen and (max-width: 768px) {
	input,
	textarea,
	select {
		width: 100%;
	}
}

@media screen and (max-width: 576px) {
	form {
		width: 90vw;
		max-width: 100%;
	}
}
