.project-divider {
	width: 0;
	height: 2px;

	border-radius: 10px;

	background: var(--primary-gradient);
}

@media screen and (max-width: 768px) {
	.project-divider {
		width: 30vw;
	}
}
