#tech-stack {
	width: 100%;
}

.banner-container {
	width: 100%;

	flex-wrap: wrap;
	justify-content: space-evenly;

	border-radius: var(--size-16);
}

.honeycomb {
	width: 300px;
	height: 300px;
}

.cell {
	height: var(--size-64);
	width: 100%;
	gap: var(--size-64);
}

.cell div {
	height: 80px;
	width: 80px;
	background: var(--white);

	border-radius: var(--size-18);
	border: 2px solid var(--black);

	transform: rotate(45deg);
}

.cell div img {
	transform: rotate(-45deg);
	width: 80%;
}

@media screen and (max-width: 576px) {
	.cell div {
		height: 70px;
		width: 70px;
		border-radius: var(--size-8);
	}
}
