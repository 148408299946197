.social-container {
	position: fixed;
	right: var(--size-24);
	top: 75vh;

	border-radius: 50px;

	background-color: var(--black);
	opacity: 0.7;

	padding: var(--size-16) var(--size-12);

	gap: var(--size-8);

	z-index: 999;
}
