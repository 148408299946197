@keyframes navAnimation {
	0% {
		left: -999px;
		opacity: 0;
	}
	100% {
		left: 0px;
		opacity: 1;
	}
}

.nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	flex-wrap: nowrap;

	/* position: fixed;
	width: 100%; */
	height: var(--size-64);
}

.nav .nav-links {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--size-28);
}

.nav .nav-link {
	list-style: none;
	position: relative;

	cursor: pointer;
}

.logo,
.nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-item.logo {
	transition: 300ms ease;
}

.nav-item.logo:hover {
	transform: scale(1.2);
}

.nav-link::before {
	content: "";
	height: 2px;
	width: 2px;

	background: var(--black);
	border-radius: 4px;

	position: absolute;
	bottom: -8px;
	left: -99999px;
}

.nav-link:hover::before {
	left: 50%;

	transform: translate(-50%, 0);
	width: 70%;
	animation: navAnimation 200ms none;
}

@media screen and (max-width: 768px) {
	.nav {
		position: fixed;

		z-index: 999;

		width: 100%;
		height: var(--size-48);

		background: var(--white);
	}

	.nav-item.nav-links.mobile {
		width: 100vw;
		flex-direction: column;

		background-color: var(--white);

		position: fixed;
		z-index: 998;

		top: var(--size-48);
		right: 0;

		display: none;
		visibility: hidden;

		transition: 0.3s ease-in;
		box-shadow: 0 15px 20px #1e1e1e0a;

		padding-top: 12px;
		padding-bottom: 12px;
	}

	.nav-item.nav-links.mobile.mobile-open {
		display: flex;
		visibility: visible;
	}
}
