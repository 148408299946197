#eclipses {
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-evenly;
	row-gap: var(--size-28);
	column-gap: var(--size-18);
}

.project-divider {
	width: 0;
	height: 2px;

	border-radius: 10px;

	background: var(--primary-gradient);
}

@media screen and (max-width: 600px) {
	.hero {
		flex-direction: column;
	}

	.hero-left {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--size-12);
	}
}
