.reviews-container {
	max-width: 1080px;
	height: 200px;

	margin-left: auto;
	margin-right: auto;

	background: var(--black);

	border-radius: var(--size-18);

	justify-content: space-around;
}

.reviews-wrapper {
	height: 100%;
	overflow: hidden;
}

.reviews {
	width: 900px;
	max-width: 900px;
	height: 100%;

	justify-content: flex-start;
	gap: 45px;
	transition: transform 0.3s ease;
}

.review-item-div {
	width: 270px;
	min-width: 270px;
	height: 85%;

	flex-shrink: 0;
	align-items: flex-start;
	justify-content: space-between;
	gap: var(--size-12);

	background-color: var(--white);
	text-align: justify;

	border-radius: var(--size-18);

	padding-top: var(--size-16);
	padding-bottom: var(--size-16);
}

.left-arrow,
.right-arrow {
	cursor: pointer;
}

@media screen and (max-width: 1200px) {
	.reviews-container {
		margin-left: var(--size-18);
		margin-right: var(--size-18);
	}
}

@media screen and (max-width: 992px) {
	.reviews-container {
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}
