.popup {
	position: fixed;
	top: 0;
	left: 0;

	z-index: 999;
	height: 100vh;
	width: 100%;

	background: #1e1e1e86;
}
