.project-container {
	min-width: 100%;
	height: 400px;
	position: relative;

	flex-wrap: wrap;
}

.black-bg {
	position: absolute;
	top: 0;
	z-index: -1;

	height: 100%;
	width: 30%;

	background-color: var(--black);
	border-radius: var(--size-18);
}

.project {
	height: 60%;
}

.project-item {
	height: 100%;
}

.project-img-container {
	flex-basis: 60%;
}

.project-img {
	height: 100%;
	object-fit: contain;
	border-radius: var(--size-18);

	border: 3px solid var(--black);
}

.project-content {
	flex-basis: 40%;
	justify-content: space-between;

	align-items: flex-start;
	text-align: justify;
	gap: var(--size-12);
}

.made-with {
	width: 100%;
	justify-content: space-around;
}

.made-with-stack {
	gap: var(--size-8);
	text-align: left;
}

@media screen and (max-width: 992px) {
	.project {
		gap: var(--size-18);
	}

	.project-img-container {
		padding-left: var(--size-18);
	}

	.project-img {
		height: 80%;
	}

	.project-content {
		flex-basis: 60%;
	}
}

@media screen and (max-width: 768px) {
	.project-container {
		min-width: 100%;
		height: fit-content;
	}

	.project {
		flex-direction: column;
		width: 100%;
	}

	.black-bg {
		height: 0;
		width: 0;
	}

	.project-item h3 {
		text-align: left;
	}

	.project-img-container {
		flex-basis: 100%;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}

	.project-img {
		width: 80%;
	}

	.made-with {
		justify-content: center;
		gap: var(--size-12);
	}
}
